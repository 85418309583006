import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuth } from '../App';
import DataTable from './DataTable';

const DeliquencyUpload = ({ onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      const response = await fetchWithAuth('/api/upload/deliquency', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        onUploadSuccess?.(result);
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
      event.target.value = '';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 mb-4">
      <div>
        <label 
          className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md cursor-pointer hover:bg-blue-700 transition-colors"
        >
          {uploading ? 'Uploading...' : 'Upload CSV'}
          <input
            type="file"
            accept=".csv"
            onChange={handleUpload}
            className="hidden"
            disabled={uploading}
          />
        </label>
      </div>
    </div>
  );
};

const DeliquencyTable = () => {
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [sendingLetters, setSendingLetters] = useState(false);

  const processData = useCallback((rawData) => {
    if (!Array.isArray(rawData)) return [];
    
    return rawData
      .filter(row => row && typeof row === 'object' && Object.keys(row).length > 0)
      .map(row => {
        const processedRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (typeof value === 'string' && !isNaN(value.replace(/,/g, ''))) {
            processedRow[key] = parseFloat(value.replace(/,/g, ''));
          } else if (typeof value === 'number' && isNaN(value)) {
            processedRow[key] = null;
          } else if (value === 'NaN') {
            processedRow[key] = null;
          } else {
            processedRow[key] = value;
          }
        });
        return processedRow;
      });
  }, []);

  const handleSendLetters = async () => {
    if (!selectedAddresses.length) return;

    try {
      setSendingLetters(true);
      const response = await fetchWithAuth('/api/deliquency-letters', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ addresses: selectedAddresses }),
      });
      
      if (response.ok) {
        // You might want to show a success message here
        setSelectedAddresses([]); // Clear selection after successful send
      } else {
        console.error('Failed to send letters');
        // You might want to show an error message here
      }
    } catch (error) {
      console.error('Error sending letters:', error);
    } finally {
      setSendingLetters(false);
    }
  };
  
  const fetchData = useCallback(async (date) => {
    try {
      setLoading(true);
      const requestUrl = `/api/data/deliquency${date ? `?date=${date}` : ''}`;
      const response = await fetchWithAuth(requestUrl);
      const result = await response.json();
      
      const processedData = processData(result.data || []);
      setData(processedData);
      
      if (!date && result.date) {
        setSelectedDate(result.date);
      }
    } catch (err) {
      console.error('Fetch data error:', err);
    } finally {
      setLoading(false);
    }
  }, [processData]);

  const fetchDates = useCallback(async () => {
    try {
      const response = await fetchWithAuth('/api/dates/deliquency');
      const result = await response.json();
      setDates(result.dates || []);
    } catch (err) {
      console.error('Fetch dates error:', err);
    }
  }, []);

  const handleUploadSuccess = useCallback((result) => {
    setData(processData(result.data || []));
    if (result.date) {
      setSelectedDate(result.date);
    }
    fetchDates();
  }, [processData, fetchDates]);

  const handleRowSelect = (selectedIds) => {
    setSelectedAddresses(selectedIds);
  };

  useEffect(() => {
    fetchDates();
    fetchData(selectedDate);
  }, [selectedDate, fetchData, fetchDates]);

  return (
    <div className="flex flex-col h-full w-full">
      <DeliquencyUpload onUploadSuccess={handleUploadSuccess} />
      <div className="bg-white rounded-lg shadow p-4 flex-grow flex flex-col">
        <div className="mb-4 flex justify-between items-center">
          <select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="p-2 border rounded-lg w-48"
          >
            <option value="">Select date</option>
            {dates.map(date => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>

          {selectedAddresses.length > 0 && (
            <button
              onClick={handleSendLetters}
              disabled={sendingLetters}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors disabled:bg-blue-400"
            >
              {sendingLetters ? 'Sending Letters...' : `Send Letters (${selectedAddresses.length})`}
            </button>
          )}
        </div>
  
        {loading ? (
          <div className="text-gray-500">Loading...</div>
        ) : data && data.length > 0 ? (
          <div className="flex-grow">
            <DataTable 
              data={data}
              visibleColumns={['Name', 'Tenant Address', 'Amount Receivable', '30+', 'Delinquent Subsidy Amount', 'Beginning Balance', 'Ending Balance', 'Change in Balance MTD']}
              columnOrder={['Name', 'Tenant Address', 'Amount Receivable', '30+', 'Delinquent Subsidy Amount',  'Beginning Balance', 'Ending Balance', 'Change in Balance MTD']}
              initialFilters={{
                "Amount Receivable": 0
              }}
              initialSort={[
                {
                  id: 'Change in Balance MTD',
                  desc: true
                }
              ]}
              onRowSelect={handleRowSelect}
              primaryKey="Tenant Address"
            />
          </div>
        ) : (
          <div className="text-gray-500">No delinquency data available</div>
        )}
      </div>
    </div>
  );
};

export default DeliquencyTable;