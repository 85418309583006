import React, { useState, useCallback } from 'react';
import { fetchWithAuth } from '../App';
import DataTable from './DataTable';

const PortfolioUpload = ({ onUploadSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const [fileType, setFileType] = useState('portfolio');

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const endpoint = fileType === 'financials' 
      ? '/api/upload/portfolio-financials' 
      : '/api/upload/portfolio';

    try {
      setUploading(true);
      const response = await fetchWithAuth(endpoint, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        onUploadSuccess?.(result);
      }
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
      event.target.value = '';
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-4 mb-4">
      <div className="flex gap-4 items-center">
        <select
          value={fileType}
          onChange={(e) => setFileType(e.target.value)}
          className="px-3 py-2 border rounded-md"
          disabled={uploading}
        >
          <option value="portfolio">Portfolio Directory</option>
          <option value="financials">Financials</option>
        </select>
        
        <label 
          className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md cursor-pointer hover:bg-blue-700 transition-colors"
        >
          {uploading ? 'Uploading...' : `Upload ${fileType === 'financials' ? 'Financials' : 'Portfolio Directory'} CSV`}
          <input
            type="file"
            accept=".csv"
            onChange={handleUpload}
            className="hidden"
            disabled={uploading}
          />
        </label>
      </div>
    </div>
  );
};

const PortfolioTable = ({ onPropertySelect }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState([]);

  const processData = useCallback((rawData) => {
    if (!Array.isArray(rawData)) return [];
    
    return rawData
      .filter(row => row && typeof row === 'object' && Object.keys(row).length > 0)
      .map(row => {
        const processedRow = {};
        Object.entries(row).forEach(([key, value]) => {
          if (typeof value === 'string' && !isNaN(value.replace(/,/g, ''))) {
            processedRow[key] = parseFloat(value.replace(/,/g, ''));
          } else if (typeof value === 'number' && isNaN(value)) {
            processedRow[key] = null;
          } else if (value === 'NaN') {
            processedRow[key] = null;
          } else {
            processedRow[key] = value;
          }
        });
        return processedRow;
      });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchWithAuth('/api/data/portfolio');
      const result = await response.json();
      
      const processedData = processData(result.data || []);
      setData(processedData);
    } catch (err) {
      console.error('Fetch data error:', err);
    } finally {
      setLoading(false);
    }
  }, [processData]);

  const handleUploadSuccess = useCallback((result) => {
    setData(processData(result.data || []));
  }, [processData]);

  const handleRowSelect = (selectedIds) => {
    setSelectedAddresses(selectedIds);
  };

  const handlePropertySearch = () => {
    if (selectedAddresses.length === 1) {
      const selectedProperty = data.find(row => row.Property === selectedAddresses[0]);
      if (selectedProperty) {
        onPropertySelect(selectedProperty.Property);
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="flex flex-col h-full w-full">
      <PortfolioUpload onUploadSuccess={handleUploadSuccess} />
      <div className="bg-white rounded-lg shadow p-4 flex-grow flex flex-col">
        {selectedAddresses.length === 1 && (
          <div className="mb-4">
            <button
              onClick={handlePropertySearch}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Property Search
            </button>
          </div>
        )}
        
        {loading ? (
          <div className="text-gray-500">Loading...</div>
        ) : data && data.length > 0 ? (
          <div className="flex-grow">
            <DataTable 
              data={data}
              visibleColumns={['Property', 'Owner(s)', 'Units', 'Bedrooms', 'Occupancy', 'Tenant Rent', 'Section 8 Rent', 'Revenue', 'Insurance', 'Management Fees', 'Taxes', 'Water', 'Other Utilities', 'Maintenance and Turnover', 'Other Expenses', 'NOI']}
              columnOrder={['Property', 'Owner(s)', 'Units', 'Bedrooms', 'Occupancy', 'Tenant Rent', 'Section 8 Rent', 'Revenue', 'Insurance', 'Management Fees', 'Taxes', 'Water', 'Other Utilities', 'Maintenance and Turnover', 'Other Expenses', 'NOI']}
              initialSort={[
                {
                  id: 'Property Name',
                  desc: false
                }
              ]}
              primaryKey="Property"
              onRowSelect={handleRowSelect}
            />
          </div>
        ) : (
          <div className="text-gray-500">No portfolio data available</div>
        )}
      </div>
    </div>
  );
};

export default PortfolioTable;
